













































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { TemplateEntityModel, TemplateQueryModel } from '@common-src/entity-model/template-entity';
import TemplateService from '@common-src/service/template';

@Component
export default class MessageTemplateListComponent extends TableDialogFormComponent<TemplateEntityModel, TemplateQueryModel> {
    TemplateEntityModel = TemplateEntityModel;
    created() {
        this.initTable({
            service: new TemplateService(),
            queryModel: new TemplateQueryModel(),
            tableColumns: TemplateEntityModel.getTableColumns()
        });
        this.getList();
    }
}

